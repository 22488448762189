// Brand
$--color-brand-primary-100: #F2F6FF;
$--color-brand-primary-200: #D9E4FF;
$--color-brand-primary-300: #A6C1FF;
$--color-brand-primary-400: #598BFF;
$--color-brand-primary-500: #3366FF;
$--color-brand-primary-600: #274BDB;
$--color-brand-primary-700: #1A34B8;
$--color-brand-primary-800: #102694;
$--color-brand-primary-900: #091C7A;

$--color-brand-primary-transparent-8: rgba(51, 102, 255, 0.08);
$--color-brand-primary-transparent-16: rgba(51, 102, 255, 0.16);
$--color-brand-primary-transparent-24: rgba(51, 102, 255, 0.24);
$--color-brand-primary-transparent-32: rgba(51, 102, 255, 0.32);
$--color-brand-primary-transparent-40: rgba(51, 102, 255, 0.40);
$--color-brand-primary-transparent-48: rgba(51, 102, 255, 0.48);

// Basic
$--color-basic-100: #FFFFFF;
$--color-basic-200: #F7F9FC;
$--color-basic-300: #EDF1F7;
$--color-basic-400: #E4E9F2;
$--color-basic-500: #C5CEE0;
$--color-basic-600: #8F9BB3;
$--color-basic-700: #2E3A59;
$--color-basic-800: #222B45;
$--color-basic-900: #192038;
$--color-basic-1000: #151A30;
$--color-basic-1100: #101426;

$--color-transparent: transparent;
$--color-basic-white-transparent-8: rgba(143, 155, 179, 0.08);
$--color-basic-white-transparent-16: rgba(143, 155, 179, 0.16);
$--color-basic-white-transparent-24: rgba(143, 155, 179, 0.24);
$--color-basic-white-transparent-32: rgba(143, 155, 179, 0.32);
$--color-basic-white-transparent-40: rgba(143, 155, 179, 0.4);
$--color-basic-white-transparent-48: rgba(143, 155, 179, 0.48);

$--color-transparent: transparent;
$--color-basic-transparent-8: rgba(255, 255, 255, 0.08);
$--color-basic-transparent-16: rgba(255, 255, 255, 0.16);
$--color-basic-transparent-24: rgba(255, 255, 255, 0.24);
$--color-basic-transparent-32: rgba(255, 255, 255, 0.32);
$--color-basic-transparent-40: rgba(255, 255, 255, 0.4);
$--color-basic-transparent-48: rgba(255, 255, 255, 0.48);

// Success
$--color-success-100: #F0FFF5;
$--color-success-200: #CCFCE3;
$--color-success-300: #8CFAC7;
$--color-success-400: #2CE69B;
$--color-success-500: #00D68F;
$--color-success-600: #00B887;
$--color-success-700: #00997A;
$--color-success-800: #007D6C;
$--color-success-900: #004A45;

$--color-success-transparent-8: rgba(0, 214, 143, 0.08);;
$--color-success-transparent-16: rgba(0, 214, 143, 0.16);;
$--color-success-transparent-24: rgba(0, 214, 143, 0.24);;
$--color-success-transparent-32: rgba(0, 214, 143, 0.32);;
$--color-success-transparent-40: rgba(0, 214, 143, 0.40);;
$--color-success-transparent-48: rgba(0, 214, 143, 0.48);;

// Info
$--color-info-100: #F2F8FF;
$--color-info-200: #C7E2FF;
$--color-info-300: #94CBFF;
$--color-info-400: #42AAFF;
$--color-info-500: #0095FF;
$--color-info-600: #006FD6;
$--color-info-700: #0057C2;
$--color-info-800: #0041A8;
$--color-info-900: #002885;

$--color-info-transparent-8: rgba(0, 149, 255, 0.08);;
$--color-info-transparent-16: rgba(0, 149, 255, 0.16);;
$--color-info-transparent-24: rgba(0, 149, 255, 0.24);;
$--color-info-transparent-32: rgba(0, 149, 255, 0.32);;
$--color-info-transparent-40: rgba(0, 149, 255, 0.40);;
$--color-info-transparent-48: rgba(0, 149, 255, 0.48);;

// Warning
$--color-warning-100: #FFFDF2;
$--color-warning-200: #FFF1C2;
$--color-warning-300: #FFE59E;
$--color-warning-400: #FFC94D;
$--color-warning-500: #FFAA00;
$--color-warning-600: #DB8B00;
$--color-warning-700: #B86E00;
$--color-warning-800: #945400;
$--color-warning-900: #703C00;

$--color-warning-transparent-8: rgba(255, 170, 0, 0.08);;
$--color-warning-transparent-16: rgba(255, 170, 0, 0.16);;
$--color-warning-transparent-24: rgba(255, 170, 0, 0.24);;
$--color-warning-transparent-32: rgba(255, 170, 0, 0.32);;
$--color-warning-transparent-40: rgba(255, 170, 0, 0.40);;
$--color-warning-transparent-48: rgba(255, 170, 0, 0.48);;

// Danger
$--color-danger-100: #FFF2F2;
$--color-danger-200: #FFD6D9;
$--color-danger-300: #FFA8B4;
$--color-danger-400: #FF708D;
$--color-danger-500: #D82400;
$--color-danger-600: #DB2C66;
$--color-danger-700: #B81D5B;
$--color-danger-800: #94124E;
$--color-danger-900: #700940;

$--color-danger-transparent-8: rgba(255, 61, 113, 0.08);;
$--color-danger-transparent-16: rgba(255, 61, 113, 0.16);;
$--color-danger-transparent-24: rgba(255, 61, 113, 0.24);;
$--color-danger-transparent-32: rgba(255, 61, 113, 0.32);;
$--color-danger-transparent-40: rgba(255, 61, 113, 0.40);;
$--color-danger-transparent-48: rgba(255, 61, 113, 0.48);;
