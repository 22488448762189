@import '../../../resources/css/colors.scss';
.form-control {
  width: 100%;

  .label {
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: $--color-basic-1100;
    &.focused {
      color: $--color-basic-1100;
    }
    transform: none;
  }
  .input {
    //Text
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
    padding: 14px;
    color: $--color-basic-1100;

    //Box
    width: 100%;
    border: 1px solid $--color-basic-400;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    background-color: $--color-basic-100;
    
    &.multiline {
      height: auto;
    }
    &.labelled {
      margin-top: 18px;
    }
    &.focused, &:hover {
      border: 1px solid $--color-brand-primary-500;
    }
    &.focused {
      box-shadow: 0 0 0 4px $--color-basic-300;
    }
    &.disabled {
      background-color: $--color-transparent;
    }
    &.error {
      border: 1px solid $--color-danger-500;
    }
    &.greybox {
      background-color: $--color-basic-200;
    }
  }
  .password-adornment {
    color: $--color-basic-1100;
  }
  
}

.error__container {
  display: flex;
  margin-top: 8px;
  align-items: center;
}
.error__icon {
  margin-right: 4px;
}
