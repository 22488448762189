@import '../../../resources/css/media-queries.scss';
@import '../../../resources/css/colors.scss';

.modal-content__container {
  padding: 24px;

  @include gt(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .title {
    flex: 0 0 100%;
    margin-right: 24px;
    margin-bottom: 16px;
    display: block;

    @include gt(md) {
      margin-bottom: 32px;
    }
  }

  .authentication-method__link, .authentication-method--enabled {
    align-self: center;
    margin-bottom: 8px;
    text-decoration: none;
    color: $--color-basic-800;
    display: block;
    
    @include gt(md) {
      width: calc(50% - 6px);
      margin-bottom: 12px;
    }
  }
  
  .authentication-method--enabled {
    position: relative;
    opacity: 0.5;
    > svg {
      position: absolute;
      top: 22px;
      right: 22px;
      color: $--color-success-500;
      width: 20px;
      height: 20px;
    }
  
    img {
      filter: grayscale(100%);
    }
  }
}

