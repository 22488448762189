@import '../../../resources/css/colors.scss';
@import '../../../resources/css/media-queries.scss';

.authentication-method {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  border-radius: 4px;
  background-color: $--color-basic-300;
  cursor: pointer;

  img {
    margin: 0 18px 0 24px;
  }
}
