@import '../../../resources/css/media-queries.scss';
@import '../../../resources/css/colors.scss';

.modal__content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .modal__content {
    width: 100%;
    max-width: 540px;
    background-color: $--color-basic-100;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    pointer-events: initial;
  
    @include gt(md) {
      width: 540px;
    }

    .loading__icon {
      margin: auto;
    }

    .modal__close-button {
      position: absolute;
      min-width: 12px;
      padding: 0;
      height: 12px;
      width: 12px;
      right: 30px;
      top: 30px;

      @include gt(md) {
        top: 38px;
        right: 38px;
      }
    }
  }
}

