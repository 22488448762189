@import '../../../../resources/css/colors.scss';
@import '../../../../resources/css/media-queries.scss';

.configuration__header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
  margin-top: 24px;
}

.auth-configurations {
  margin-bottom: 48px;
}

.auth-method {
  border-bottom: 1px solid $--color-basic-400;

  &:last-of-type {
    border-bottom: none;
  }
}

.user-signup {
  padding: 24px;
  background-color: $--color-basic-300;
  margin-bottom: 48px;

  label {
    > span:first-of-type {
      padding-top: 0;
      padding-bottom: 0;
    }
    > span:nth-of-type(2) {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .user-signup__caption {
    display: block;
    margin-left: 32px;
    color: $--color-basic-700;

    @include gt(md) {
      max-width: 263px;
    }
  }
}

.application-tokens__label {
  display: block;
  margin-bottom: 24px;
}

.input {
  display: block;
  max-width: 280px;
  &.token-expiry__input {
    margin-bottom: 24px;
  }
  &.refresh-token-expiry__input {
    margin-bottom: 48px;
  }
}

.add-auth__button {
  width: auto;
  min-width: unset;
  .add-auth__button__text {
    display: none;
    @include gt(md) {
      display: inline;
    }
  }
}

.disable-identity__container {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background-color: $--color-basic-300;
  flex-wrap: wrap;

  > span {
    margin-bottom: 16px;
  }

  @include gt(md) {
    flex-wrap: nowrap;

    span {
      margin-bottom: 0;
    }
  }
}
