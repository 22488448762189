@import '../../../../../resources/css/colors.scss';

.authentication-method-accordion {
  position: relative;
  box-shadow: none;

  &::before {
    content: none;
  }

  .configuration__control {
    padding: 0 !important;
    background-color: $--color-basic-300;
  }

  .control-content__container {
    margin: 0;
  }

  .expand__icon {
    position: absolute;
    right: 20px;
  }

  .configuration-details {
    background-color: $--color-basic-300;
    padding: 0 0 24px 32px;
    flex-wrap: wrap;
    
    .configuration-details__container {
      max-width: 400px;
      margin-bottom: 16px;
      flex: 0 0 100%;
      margin-right: 32px;
    }
  }

  .disable__container {
    box-sizing: border-box;
    flex: 0 1 100%;
    border-radius: 4px;
    background-color: $--color-basic-400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .button__container {
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $--color-basic-400;

    button:first-of-type {
      margin-right: 12px;
    }
  }
}
