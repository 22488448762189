@import '../../../resources/css/media-queries.scss';
@import '../../../resources/css/colors.scss';

.list-template {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;

  .title__container {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    border-bottom: 1px solid $--color-basic-400;
    padding-bottom: 8px;
    align-items: flex-end;

    span {
      font-weight: 600;
    }
  }


  @include gt(md) {
    margin-bottom: 9px;
  }

  @include gt(lg) {
    margin: 0 auto 16px;
    max-width: 1120px;
  }
}
