@import '../../../resources/css/colors.scss';

.menu-item {
  width: 188px;
  height: 48px;
  border-bottom: 1px solid $--color-basic-300;

  &:last-of-type {
    border-bottom: none;
  }

  .menu__link {
    text-decoration: none;
    color: $--color-basic-800;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
