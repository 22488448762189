@import '../../../resources/css/media-queries.scss';
@import '../../../resources/css/colors.scss';

.text {
  font-family: 'Inter', 'sans-serif';
  font-style: normal;
  font-weight: 500;
}

.subtitle {
  font-weight: 500;
  line-height: 24px;
}

.subtitle.s1 {
  font-size: 15px;
} 

.subtitle.s2 {
  font-size: 13px;
  font-weight: 500;
} 

.paragraph {
  font-weight: 400;
}

.paragraph.p1 {
  font-size: 16px;
  line-height: 24px;
}

.paragraph.p2 {
  font-size: 13px;
  line-height: 18px;
}

.caption.c1 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.caption.c2 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  
  &.underlined {
    padding-bottom: 8px;
    border-bottom: 1px solid $--color-basic-400;
    display: block;
  }
}

.error.e1 {
  font-size: 12px;
  line-height: 16px;
  color: $--color-danger-500;
}

.title {
  font-weight: 600;

  &.t1 {
    font-size: 36px;
    line-height: 48px;
  }
}

.heading {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-feature-settings: 'cv05' on, 'cv06' on;

  &.underlined {
    padding-bottom: 8px;
    border-bottom: 1px solid $--color-basic-400;
    display: block;
  }
  
  &.h1 {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.015em;
  }
  
  &.h2 {
    font-size: 32px;
    line-height: 40px;
  }
  
  &.h3 {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.015em;
  }
  
  &.h4 {
    font-size: 26px;
    line-height: 32px;
  }
  
  &.h5 {
    font-size: 22px;
    line-height: 32px;
  }
  
  &.h6 {
    font-size: 18px;
    line-height: 24px;
  }
}
