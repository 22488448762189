@import "../../../resources/css/colors.scss";

.button {
  font-family: 'Inter', 'sans-serif';
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
  line-height: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: $--color-basic-100;
  display: inline-flex;
  align-items: center;
  justify-content : center;
  border: 1px solid $--color-transparent;
  text-transform: none;
  box-shadow: none;

  &.loading {
    color: $--color-basic-100;
  }
}

.xs {
  min-width: 66px;
  height: 24px;
  font-size: 10px;
  line-height: 12px;
  padding: 0 12px;
}
.sm {
  min-width: 83px;
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 16px;
}
.md {
  font-size: 14px;
  line-height: 16px;
  min-width: 99px;
  height: 40px;
  padding: 0 20px;
}
.lg {
  min-width: 108px;
  height: 48px;
  line-height: 20px;
  font-size: 16px;
  padding: 0 20px;
}
.xl {
  min-width: 124px;
  height: 56px;
  font-size: 18px;
  line-height: 24px;
  padding: 0 24px;
}

/**
 *  GREY FILL
/*
/* default grey */
.button.grey {
  color: $--color-basic-700;
  background-color: $--color-basic-400;
}
/* grey button hover */
.button.grey:hover {
  background-color: $--color-basic-500;
}
/* grey button active, focus */
.button.grey.active, 
.button.grey:focus {
  background-color: $--color-basic-600;
}
/* grey button focus */
.button.grey.focus {
  border: 4px solid $--color-basic-transparent-16;
}

/**
 *  BRAND FILL
/*
/* default brand */
.button.brand {
  background-color: $--color-brand-primary-500;
}
/* brand button hover */
.button.brand:hover {
  background-color: $--color-brand-primary-400;
}
/* brand button active, focus */
.button.brand.active, 
.button.brand:focus {
  background-color: $--color-brand-primary-600;
}
/* brand button focus */
.button.brand.focus {
  border: 4px solid $--color-basic-transparent-16;
}
/**
 *  BRAND OUTLINE
/*
/* default brand outlined */
.button.brand.outlined {
  color: $--color-brand-primary-500;
  border-color: $--color-brand-primary-500;
  box-shadow: none;
}
/* brand button outlined hover */
.button.brand.outlined:hover {
  background-color: $--color-brand-primary-transparent-8;
}
/* brand button outlined active, focus */
.button.brand.outlined.active, 
.button.brand.outlined:focus {
  background-color: $--color-brand-primary-transparent-16;
}
/**
 *  BRAND GHOST
/*
/* default brand ghost */
.button.brand.ghost {
  color: $--color-brand-primary-500;
  background-color: $--color-transparent;
  box-shadow: none;
}
/* brand button hover */
.button.brand.ghost:hover {
  background-color: $--color-brand-primary-transparent-8;
}
/* brand button active */
.button.brand.ghost.active {
  background-color: $--color-brand-primary-transparent-16;
}
/* brand button focus */
.button.brand.ghost.focus {
  background-color: $--color-basic-transparent-16;
}

/* default success */
.button.success {
  background-color: $--color-success-400;
}
/* success button hover, active, focus */
.button.success:hover,
.button.success.active,
.button.success:focus {
  background-color: $--color-success-300;
}
/* default success outlined */
.button.success.outlined {
  color: $--color-success-400;
  border-color: $--color-success-400;
}
/* success outlined hover, active, focus */
.button.success.outlined:hover,
.button.success.outlined.active, 
.button.success.outlined:focus {
  color: $--color-basic-100;
  border-color: $--color-transparent;
  background-color: $--color-success-400;
} 

/* default danger */
.button.danger {
  background-color: $--color-danger-500;
}
/* danger hover, active, focus */
.button.danger:hover, 
.button.danger.active, 
.button.danger:focus {
  background-color: $--color-danger-200;
}
/* default danger outlined */
.button.danger.outlined {
  color: $--color-danger-500;
  border-color: $--color-danger-500;
}
/* danger outlined hover, active, focus */
.button.danger.outlined:hover {
  background-color: $--color-danger-transparent-8;
} 
.button.danger.outlined.active, 
.button.danger.outlined:focus {
  background-color: $--color-danger-transparent-16;
}

/* default outlined */
.button.outlined {
  background-color: $--color-transparent;
}
/* outlined hover, active, focus */
.button.outlined:hover, 
.button.outlined.active, 
.button.outlined:focus {
  background-color: $--color-basic-100;
}

/* default text */
.button.text {
  box-shadow: none;
  color: $--color-basic-200;
}

/* active, focus */
.button.active,
.button:focus,
.button.outlined.active, 
.button.outlined:focus {
  outline: none;
  box-shadow: 0 0 0 1pt $--color-basic-300;
}

/* default disabled */
.button.disabled, .button.disabled:hover {
  background-color: $--color-basic-400;
  border-color: $--color-basic-400;
  color: $--color-basic-500;
}
/* default outlined disabled */
.button.disabled.outlined, .button.disabled.outlined:hover {
  background-color: $--color-basic-100;
  border-color: $--color-basic-500;
  color: $--color-basic-500;
}

.spinner {
  color: inherit;
}
