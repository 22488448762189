@import '../../../resources/css/colors.scss';
@import '../../../resources/css/media-queries.scss';

.tabs {
  display: none;
  @include gt(lg) {
    display: flex;
  }

  a {
    justify-content: center;
  }
  
  button {
    font-weight: 600;
    size: 14px;
    line-height: 16px;
    min-width: unset;
    text-transform: none;
    color: $--color-basic-800;
    opacity: 1;
    outline: none;
  }

  .indicator {
    background-color: $--color-brand-primary-500;
    height: 4px;
  }

  [aria-selected="true"] {
    color: $--color-brand-primary-500;
  }
}
