@import '../../../resources/css/colors.scss';

.checkbox {
  margin-bottom: 0;
  > span {
    &:nth-of-type(1) {
      color: $--color-brand-primary-500;
      &:hover {
        background-color: $--color-transparent;
      }
    }

    &:nth-of-type(2) {
      color: $--color-basic-800;
    }
  }

}
