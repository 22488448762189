@import '../../../../resources/css/colors.scss';
@import '../../../../resources/css/media-queries.scss';

.overview__container {
  height: 276px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $--color-basic-300;

  @include gt(md) {
    height: 312px;
  }

  @include gt(lg) {
    height: 458px;
  }
}
