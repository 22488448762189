@import '../../../../../resources/css/colors.scss';

.facebook-auth {
  margin-top:0px !important; 
  margin-bottom: 0 !important; 
  .input__container {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .app-id__caption {
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: $--color-brand-primary-500;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  }
}
