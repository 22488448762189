@import '../../../../../resources/css/colors.scss';

.application-urls__label {
  display: block;
  margin-bottom: 24px;
}

.input {
  display: block;
  max-width: 280px;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 48px;
  }
}

.button__container {
  max-width: 280px;
  padding-top: 16px;
  margin-bottom: 48px;
  border-top: 1px solid $--color-basic-400;

  button:first-of-type {
    margin-right: 12px;
  }
}
