@import "../../../../resources/css/colors.scss";
@import "../../../../resources/css/media-queries.scss";

.users {
  margin-top: 24px;
}

.menu {
  margin-top: 56px;
}

.email {
  margin-top: 30px;
  width: 100%;
}
