@import '../../../resources/css/colors.scss';

.user-list {
  width: 100%;
  .user-list-item {
    border-bottom: 1px solid $--color-basic-400;
  
    &:last-of-type() {
      margin-bottom: 0;
    }
  }  
}
