@import '../../../../resources/css/media-queries.scss';
@import '../../../../resources/css/colors.scss';

.user-list-item {
  display: flex;
  width: 100%;
  padding: 16px 0;

  @include gt(md) {
    padding: 24px 0;
  }

  .user-list-item__info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .user-list-item__email-text {
    flex: 0 0 100%;
    word-break: break-all;
    align-self: center;
  }

  .menu-button {
    display: flex;

    @include gt(md) {
      width: 115px;
      padding: 0 13px;
    }
    
    .button__label--desktop {
      display: none;
      align-items: center;
      @include gt(md) {
        display: flex;

      }
      svg:first-of-type {
        width: 14px;
        height: 14px;
        margin-right: 5px;
  
        &.checkmark {
          color: $--color-success-500;
        }
        &.cancel {
          color: $--color-danger-500;
        }
        &.expired {
          color: $--color-danger-500;
        }
        &.pending {
          color: $--color-warning-600;
        }
      }
    }

    .button__label--mobile {
      @include gt(md) {
        display: none;
      }
    }
  }
}
