@import '../../../../resources/css/colors.scss';
@import '../../../../resources/css/media-queries.scss';

.home {
  .title__container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
    border-bottom: 1px solid $--color-basic-400;

    .title {
      display: block;
    }
  
    .thumbnail {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-right: 12px;
      background-color: $--color-basic-500;
    }

    > div {
      display: flex;
      flex: 0 0 100%;
      margin-bottom: 10px;
    }

    @include gt(md) {
      flex-wrap: nowrap;
      justify-content: space-between;

      > div {
        flex: none;
      }

    }

    @include gt(lg) {
      padding-bottom: 0;
      border-bottom: none;

      > div {
        margin-bottom: 28px;
      }
    }
  }

  .tabs {
    border-bottom: 1px solid $--color-basic-400;
    width: 100%;
    height: 48px;

    .tab {
      padding: 0!important;
      height: 48px;
      a {
        padding: 0 16px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $--color-basic-800;
        width: 100%;
        height: 48px;
      }

      &.selected a {
        color: $--color-brand-primary-500;
      }
    }
  }

  .back-button {
    width: auto;
    height: auto;
    min-width: unset;
    padding: 0;
    margin-bottom: 8px;
    color: $--color-brand-primary-500;
  }

  .menu-button {
    @include gt(md) {
      margin-right: 60px;
    }

    @include gt(lg) {
      display: none;
    }
  }
}
