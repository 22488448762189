@import '../../../resources/css/colors.scss';

.container {
  margin: 32px;

  .divider {
    margin: 0;
    border-bottom: 1px solid $--color-basic-400;
  }
}

.padding {
  padding-top: 32px;
}

.content_margin {
  margin-top: 12px;
  margin-bottom:32px;
}

.confirm_button {
  margin-top: 32px;
}

.cancel_button {
  margin-top: 32px;
  margin-left: 8px;
}
