@import '../../../resources/css/media-queries.scss';

.app-layout {
  padding: 32px 20px;

  @include gt(md) {
    padding: 32px 60px;
  }

  @include gt(lg) {
    padding: 40px 160px;
  }
}
